/* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 1148px;
  }
}

/* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
.container, .section-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after, .section-container:before, .section-container:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after, .section-container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1148px;
  }
}

@media (min-width: 1300px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1270px;
  }
}

@media (min-width: 1400px) {
  /* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1370px;
  }
}

/* line 38, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 38, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 38, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 38, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 47, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 47, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 47, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 47, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/*
 * A partial implementation of the Ruby list functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/lists.rb
 */
/*
 * A partial implementation of the Ruby constants functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/constants.rb
 */
/*
 * A partial implementation of the Ruby display functions from Compass:
 * https://github.com/Compass/compass/blob/stable/core/lib/compass/core/sass_extensions/functions/display.rb
 */
/* line 6, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/base/_helpers.scss */
.node-program #program-outline, .node-program #admission-requirements, .node-program #career-opportunities, .node-program #program-fees, .node-program #group-intake {
  scroll-margin-top: 100px !important;
}

/**
 * Fix for vw, vh, vmin, vmax on iOS 7.
 * http://caniuse.com/#feat=viewport-units
 *
 * This fix works by replacing viewport units with px values on known screen sizes.
 *
 * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
 * Target devices running iOS 8+ will incidentally execute the media query,
 * but this will still produce the expected result; so this is not a problem.
 *
 * As an example, replace:
 *
 *   height: 50vh;
 *   font-size: 5vmin;
 *
 * with:
 *
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
/* line 7, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.program-header ul {
  margin-left: 0;
}

/* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.program-header li {
  margin-bottom: 1em;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 25%;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 15, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.program-header .field-item {
  font-weight: bold;
  color: #003E5B;
}

/* line 20, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.field-label:before {
  font-family: FontAwesome;
  margin-right: .5em;
}

/* line 24, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.field-program-length .field-label:before {
  content: "\f133";
}

/* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.field-credential .field-label:before {
  content: "\f19d";
}

/* line 30, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.field-program-options .field-label:before {
  content: "\f14a";
}

/* line 33, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.field-campus .field-label:before {
  content: "\f041";
}

/* line 37, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.field-intake-next .field-label:before {
  content: "\f252";
}

/* line 41, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.field-program-website a {
  display: table;
  margin: 1.5em 0;
  padding: 0.6em 1em;
  border-radius: 500px;
  border: 1px solid;
  line-height: 1.2em;
  text-align: center;
}

/* line 37, /home/boquistm/projects/d7/viu-home-location/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.field-program-website a:hover, .field-program-website a:focus {
  text-decoration: none;
}

/* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.program-website-links {
  clear: both;
}

/* line 47, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.program-website-links .field, .program-website-links .cta-button {
  display: inline-block;
  vertical-align: top;
  margin-right: .5em;
}

/* line 56, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.node-program .view-display-id-intake_pre_open .view-content, .node-program .view-display-id-intake_open .view-content {
  margin: 1.5em 0;
  padding: 0.6em 0;
}

/* line 65, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__intake_next {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 65, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 65, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 65, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 65, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    margin-left: 33.33333%;
  }
}

/* line 69, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__accepting_apply {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  /* adjust for above mixin not actually implemented properly in twbs 3 */
}

@media (min-width: 768px) {
  /* line 69, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 69, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    margin-left: -33.33333%;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    margin-left: -66.6666%;
  }
}

/* line 77, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__program_website {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 77, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 77, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 77, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 82, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.row--apps_open .program_node__applications_open {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 82, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 82, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  /* line 82, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 85, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.row--apps_open .program_node__program_website {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 85, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 85, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  /* line 85, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 91, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .node-program .field-intake-pre {
    float: left;
    margin-right: 2em;
  }
  /* line 95, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
  .node-program .field-program-website {
    float: right;
  }
}

/* Intake tables */
/* line 102, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.clickable {
  cursor: pointer;
}

/* line 106, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.clickable:not(.collapsed) .fa-plus-square:before {
  content: "\f146";
}

/* line 111, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/layout/_program_node.scss */
.viuIntake__detail {
  margin-bottom: 0.4em;
}

/**
 * Live result widget.

 -- Moved to viu_theme

 */
/* line 5, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_courselist.scss */
.inactive_course_desc h2, .inactive_course_desc h3, .inactive_course_desc h4, .inactive_course_desc p {
  color: #999999;
}

/* line 3, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_facts.scss */
.viuFact__heading {
  text-transform: none;
  font-weight: bold;
  font-size: 1.75em;
  margin-bottom: 0em;
}

/* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_facts.scss */
.viuFact__subtext {
  color: #003E5B;
  margin-bottom: 1em;
}

/* line 15, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_facts.scss */
.viu-quick-facts-img {
  max-width: 80%;
  display: block;
  margin: 0 auto;
}

@media (min-width: 992px) {
  /* line 4, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_flex_ctas.scss */
  .globalCTAs__cta {
    padding-right: 2em;
  }
}

/* line 10, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_flex_ctas.scss */
.pane-viu-flex-ctas .globalCTAs__cta {
  text-align: left;
}

/* line 5, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.views-widget-filter-field_program_area_search_tid {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 5, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_program_area_search_tid {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 5, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_program_area_search_tid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 5, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_program_area_search_tid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 9, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.views-widget-filter-field_credential_tid {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 9, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_credential_tid {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 9, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_credential_tid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 9, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_credential_tid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 13, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.views-widget-filter-field_viu_intake_location_tid {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

/* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.views-widget-filter-field_viu_international_value {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_viu_international_value {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_viu_international_value {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_viu_international_value {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 22, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.views-widget-filter-field_terms, .views-widget-filter-field_terms_tid {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 22, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_terms, .views-widget-filter-field_terms_tid {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 22, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_terms, .views-widget-filter-field_terms_tid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 22, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_terms, .views-widget-filter-field_terms_tid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 28, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.views-widget-filter-field_viu_prog_date_value, .views-widget-filter-title {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 28, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_viu_prog_date_value, .views-widget-filter-title {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 28, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_viu_prog_date_value, .views-widget-filter-title {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 28, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
  .views-widget-filter-field_viu_prog_date_value, .views-widget-filter-title {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 32, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.views-widget-filter-nid {
  display: none;
}

/* line 37, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_form.scss */
.view-program-search .views-reset-button, .view-program-search .views-submit-button, .view-program-intake-search .views-reset-button, .view-program-intake-search .views-submit-button, .view-program-archives .views-reset-button, .view-program-archives .views-submit-button {
  float: right;
  padding: .5em .5em 0 0;
}

/* line 4, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input {
  font-size: 18px !important;
}

/* line 7, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input .gsc-input-box {
  height: 70px;
  border: none;
}

/* line 12, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input .gsc-input-box, .gsc-input .gsc-input-box-hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* line 18, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input input {
  border: 1px solid #DDD !important;
  color: #003E5B;
  border-radius: 500px;
  background-image: url(/profiles/viu/themes/viu_theme/assets/images/icon/search-icon.svg) !important;
  background-size: 20px !important;
  background-position: right 1em top 50% !important;
  background-repeat: no-repeat !important;
  padding: 0.5em 1em !important;
  line-height: 18px !important;
  height: 3em !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/* line 34, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input input:focus {
  border-color: #66afe9 !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}

/* line 41, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input ::placeholder {
  color: transparent !important;
}

/* line 45, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input ::-webkit-input-placeholder {
  color: transparent !important;
}

/* line 49, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input :-moz-placeholder {
  /* Firefox 18- */
  color: transparent !important;
}

/* line 53, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input ::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent !important;
}

/* line 57, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input :-ms-input-placeholder {
  color: transparent !important;
}

/* line 62, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
}

/* line 66, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white !important;
}

/* line 71, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-search-button, .gsib_b, .gs-per-result-labels {
  display: none;
}

/* line 75, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gs-snippet, .gsc-control-cse, .gsc-control-cse .gs-spelling,
.gs-result .gs-title, .gsc-control-cse .gs-result .gs-title * {
  font-family: ProximaNova, sans-serif !important;
  font-size: 18px !important;
}

/* line 82, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-control-cse tbody {
  border: none !important;
}

/* line 85, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-control-cse tbody th, .gsc-control-cse tbody td {
  border: none !important;
}

/* line 91, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gs-snippet {
  color: #3b3b3b !important;
}

/* line 95, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gs-webResult {
  border: none;
}

/* line 99, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gcsc-branding {
  display: none !important;
}

/* line 103, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gs-title {
  text-decoration: none !important;
}

/* line 106, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gs-title a, .gs-title b, .gs-title em {
  color: #337ab7 !important;
  text-decoration: none !important;
}

/* line 113, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gs-title:hover a, .gs-title:hover b, .gs-title:hover em {
  text-decoration: underline !important;
  color: #24adf2 !important;
}

/* line 119, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-url-bottom {
  font-size: 18px !important;
  margin-top: 5px !important;
}

/* line 124, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-cursor-box {
  text-align: center !important;
}

/* line 128, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-cursor-page {
  color: #337ab7 !important;
  text-decoration: none !important;
  padding: 8px 12px !important;
  border: 1px solid #ddd;
  margin-right: 4px !important;
}

/* line 135, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-cursor-page:hover {
  background-color: #eeeeef !important;
}

/* line 140, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-cursor-current-page {
  color: white !important;
  border-color: #337ab7 !important;
  background-color: #337ab7 !important;
}

/* line 145, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-cursor-current-page:hover {
  background-color: #337ab7 !important;
}

/* line 151, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-tabsArea {
  font-size: 14px !important;
}

/* line 155, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* line 160, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-tabHeader.gsc-tabhActive {
  color: #2D83C5 !important;
}

/* line 163, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_google_search.scss */
.gsc-tabHeader.gsc-tabhInactive {
  color: #666666 !important;
}

/* line 9, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.news_events_title {
  font-size: 2em;
}

/* line 13, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuEvents__event,
.viuNews__story {
  margin-bottom: 2em;
}

/* line 18, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuNews__story__heading, .viuEvents__event__heading {
  font-size: 1em;
  margin-top: 0;
}

@media (min-width: 768px) {
  /* line 18, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
  .viuNews__story__heading, .viuEvents__event__heading {
    font-size: 19px;
  }
}

@media (min-width: 992px) {
  /* line 18, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
  .viuNews__story__heading, .viuEvents__event__heading {
    font-size: 22px;
  }
}

/* line 27, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuNews__story__heading a, .viuEvents__event__heading a {
  text-decoration: none !important;
}

/* line 33, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
#viuNewsEvents .viuNews__story__heading,
#viuNewsEvents .viuEvents__event__heading {
  margin: 0 0 .5em 0;
}

/* line 36, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
#viuNewsEvents .viuNews__story__heading a,
#viuNewsEvents .viuEvents__event__heading a {
  color: white !important;
}

/* line 42, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuNews__story__photo,
.viuEvents__event__dateicon {
  float: right;
  margin-left: 10px;
}

/* line 48, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon {
  position: relative;
  background: transparent;
  border: 0;
  height: 60px;
  width: 60px;
  padding: 5px 0;
  border: 3px solid;
}

/* line 57, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-month-wrapper {
  background: transparent;
  margin: 0;
  line-height: 1em;
}

/* line 63, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-month {
  color: inherit;
}

/* line 67, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-day-wrapper,
.viuEvents__event__dateicon .oa-event-date-day {
  font-size: 22px;
  font-weight: bold;
  line-height: 0.9em;
}

/* line 75, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_news_events.scss */
.page-section--text-only.page-section--color-1 .viuEvents__event__dateicon, .page-section--text-only.page-section--color-2 .viuEvents__event__dateicon {
  border-color: white;
}

/*.webform-component--i-would-like-more-information-on{
  background-color:#ededed;
  border: 1px solid #cccccc;
  padding:2em 2em;
  margin-bottom:2em;
  overflow:hidden;
  border-radius: 5px;
}

.webform-component--i-would-like-more-information-on > label{
  font-size: 20px;
  font-weight:normal;
  display: block;
}
*/
/* line 16, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
.webform-component--i-would-like-more-information-on .radio + .radio, .webform-component--i-would-like-more-information-on .checkbox + .checkbox {
  margin-top: 0.4em;
}

/* line 20, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
#webform-client-form-3228 h4 {
  margin-top: 0;
  padding-top: 1.5em;
  clear: left;
}

@media (min-width: 720px) {
  /*
  .webform-component--i-would-like-more-information-on{
    padding:2em 4em;
  }

  .webform-component--i-would-like-more-information-on > label{
    font-size: 24px;
    text-align: center;
    font-weight:normal;
    display: block;
  }

  .webform-component--i-would-like-more-information-on .form-item{
    float:left;
    width:33%;
  }
*/
  /* line 44, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
  #webform-client-form-3228 .webform-component--first-name {
    float: left;
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  /* line 49, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
  #webform-client-form-3228 .webform-component--last-name {
    float: left;
    width: calc(50% - 10px);
  }
  /* line 54, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
  #webform-client-form-3228 .webform-component--have-you-been-issued-a-viu-student-number {
    float: left;
    width: calc(60% - 10px);
    margin-right: 20px;
    margin-top: 1em;
  }
  /* line 60, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
  #edit-submitted-have-you-been-issued-a-viu-student-number {
    display: inline-block;
  }
  /* line 63, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
  #webform-client-form-3228 .webform-component--student-number {
    width: calc(40% - 10px);
  }
}

@media (min-width: 1400px) {
  /* line 69, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
  #webform-client-form-3228 .webform-component--student-number {
    float: left;
  }
}

/* Hide referrer field */
/* line 75, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_contact_page.scss */
.webform-component--referrer, .webform-component--browser-info {
  display: none;
}

/* line 3, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/components/_viu_program.scss */
.viu__program_options {
  font-size: 80%;
}

/* line 2, /home/boquistm/projects/d7/viu-home-location/sites/all/themes/viu_home_theme/scss/ab_variant/_home_variant.scss */
.variant2 #audience-search {
  background: #0084C5;
}
