// Google Custom Search Engine widget styling
// Excessive use of !important due to Google providing it's own css during pageload

.gsc-input {
  font-size: 18px !important;

  .gsc-input-box {
    height: 70px;
    border: none;
  }

  .gsc-input-box, .gsc-input-box-hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  input {
    border: 1px solid #DDD !important;
    color: #003E5B;
    border-radius: 500px;
    background-image: url(/profiles/viu/themes/viu_theme/assets/images/icon/search-icon.svg) !important;
    background-size: 20px !important;
    background-position: right 1em top 50% !important;
    background-repeat: no-repeat !important;
    padding: 0.5em 1em !important;
    line-height: 18px !important;
    height: 3em !important;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    &:focus {
      border-color: #66afe9 !important;
      outline: 0 !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
    }
  }

  ::placeholder {
    color:transparent !important;
  }

  ::-webkit-input-placeholder {
    color:transparent !important;
  }

  :-moz-placeholder { /* Firefox 18- */
    color:transparent !important;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color:transparent !important;
  }

  :-ms-input-placeholder {
    color:transparent !important;
  }


  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white !important;
  }
}

.gsc-search-button, .gsib_b, .gs-per-result-labels {
  display: none;
}

.gs-snippet, .gsc-control-cse, .gsc-control-cse .gs-spelling,
.gs-result .gs-title, .gsc-control-cse .gs-result .gs-title * {
  font-family: ProximaNova, sans-serif !important;
  font-size: 18px !important;
}

.gsc-control-cse {
  tbody {
    border: none !important;

    th, td {
      border: none !important;
    }
  }
}

.gs-snippet {
  color: #3b3b3b !important;
}

.gs-webResult {
  border: none;
}

.gcsc-branding {
  display: none !important;
}

.gs-title {
  text-decoration: none !important;

  a, b, em {
    color: #337ab7 !important;
    text-decoration: none !important;
  }
}

.gs-title:hover {
  a, b, em {
    text-decoration: underline !important;
    color: #24adf2 !important;
  }
}

.gsc-url-bottom {
  font-size: 18px !important;
  margin-top: 5px !important;
}

.gsc-cursor-box {
  text-align: center !important;
}

.gsc-cursor-page {
  color: #337ab7 !important;
  text-decoration: none !important;
  padding: 8px 12px !important;
  border: 1px solid #ddd;
  margin-right: 4px !important;

  &:hover {
    background-color: #eeeeef !important;
  }
}

.gsc-cursor-current-page {
  color: white !important;
  border-color: #337ab7 !important;
  background-color: #337ab7 !important;

  &:hover {
    background-color: #337ab7 !important;
  }

}

.gsc-tabsArea {
  font-size: 14px !important;
}

.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.gsc-tabHeader.gsc-tabhActive{
  color:#2D83C5 !important;
}
.gsc-tabHeader.gsc-tabhInactive{
  color:#666666 !important;
}