// Styles for Forms on VIU Home site
// --------------------------------------------------

// Program finder
.views-widget-filter-field_program_area_search_tid {
  @include make-xs-column(12);
  @include make-sm-column(6);
}
.views-widget-filter-field_credential_tid {
  @include make-xs-column(12);
  @include make-sm-column(6);
}
.views-widget-filter-field_viu_intake_location_tid {
  @include make-xs-column(12);
}
.views-widget-filter-field_viu_international_value {
  @include make-xs-column(12);
  @include make-sm-column(6);
}
// end Program finder

.views-widget-filter-field_terms, .views-widget-filter-field_terms_tid {
  @include make-xs-column(12);
  @include make-sm-column(6);
}

// Archive form
.views-widget-filter-field_viu_prog_date_value, .views-widget-filter-title {
  @include make-xs-column(12);
  @include make-sm-column(6);
}
.views-widget-filter-nid {
  display: none;
}

.view-program-search, .view-program-intake-search, .view-program-archives {
  .views-reset-button, .views-submit-button {
    float: right;
    padding: .5em .5em 0 0;
  }
}
